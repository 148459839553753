import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
import { Demo } from '../../../components/Demo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "switch",
      "style": {
        "position": "relative"
      }
    }}>{`Switch`}</h1>
    <p>{`Switch is a control that is used to quickly switch between two possible states. Switches are used for binary actions that occur immediately after the user “flips” the switch. They are commonly used for “On/Off” situations.`}</p>
    <h2 {...{
      "id": "best-practices",
      "style": {
        "position": "relative"
      }
    }}>{`Best practices`}</h2>
    <ul>
      <li parentName="ul">{`A label may accompany a Switch to further clarify on the action the switch will perform.`}</li>
      <li parentName="ul">{`Use adjectives rather than verbs to describe labels and the state of the object affected. (Ex: On/Off)`}</li>
      <li parentName="ul">{`Labels should be three words or less and appear on the right side of a Switch.`}</li>
      <li parentName="ul">{`A Switch is used to alternate between two possible states. To use more than 2 states, check `}<a parentName="li" {...{
          "href": "/components/checkbox"
        }}>{`Checkbox`}</a>{` or `}<a parentName="li" {...{
          "href": "/components/radio-button"
        }}>{`Radio Button`}</a>{` components.`}</li>
      <li parentName="ul">{`On hover state, a hint is showed with the result of the action. (E.g.: Disable action).`}</li>
    </ul>
    <h2 {...{
      "id": "example",
      "style": {
        "position": "relative"
      }
    }}>{`Example`}</h2>
    <Demo src='pages/components/switch/SwitchDefault' mdxType="Demo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      